<template>
  <section>
    <v-dialog
      v-model="dialog"
      class="width-dialog-sm-and-down dialog-box"
      width="430"
    >
      <div class="dialog-container width-dialog-sm-and-down">
        <div class="dialog-header">
          <h2>Other Document</h2>
        </div>
        <div
          class="dialog-body"
        >
          <div
            class="transparent "
          >
            <el-form
              ref="form"
              :model="formData"
              :rules="rules"
            >
              <div
                class="form-item">
                <span>File Name</span>
                <el-form-item
                  ref="name"
                  prop="name"
                  :rules="rules.input">
                  <v-input
                    v-model="formData.name"
                  ></v-input>
                </el-form-item>
              </div>
              <div class="form-item ">
                <span>File</span>
                <el-form-item
                  prop="file_id"
                  :rules="rules.upload">
                  <file-container-card
                    :edit="true"
                    is-dialog
                    :fileData="formData.upload_file"
                    :otherData="{
                      idKey: 'file_id',
                      urlKey: 'url',
                      client_name:'client_name',
                      nameKey:'upload_file',
                    }"
                    @view="handleView"
                    @delete="handlerDeleteFile"
                    @uploadSuccess="handlerUploadSuccess" />
                </el-form-item>
              </div>
            </el-form>
          </div>

        </div>
        <div
          class="dialog-footer"
          align="right">
          <div class="btn-container">
            <v-button
              size="small"
              class="button-theme-block"
              @click="handleCancel">Cancel
            </v-button>
            <v-button
              size="small"
              type="primary"
              class="button-theme"
              @click="handlerSave">Confirm
            </v-button>
          </div>
        </div>
      </div>
    </v-dialog>
    <!--    <v-dialog-->
    <!--      v-model="isOpen"-->
    <!--      showClose-->
    <!--      class="view-dialog"-->
    <!--      fullscreen>-->
    <!--      <embed-->
    <!--        :src="fileUrl"-->
    <!--        width="100%"-->
    <!--        height="100%"-->
    <!--        type="application/pdf">-->
    <!--    </v-dialog>-->
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import {updateDoc} from '@/api/order'
import MixinRules from '@/mixins/rules'

export default {
  name: 'uploadOtherDoucment',
  mixins: [MixinOptions,MixinRules],

  props:{
    form: {
      type: Object,
      default: () => ({})
    },
    value:{
      type: Boolean,
      default:false,//1// hire 2:issue
    },
  },
  data() {
    return {
      dialog:false,
      formData:{
        upload_file:{},
        file_id:'',
        name:''
      }
    }
  },
  computed:{
    fileUrl(){
      let {url} =this.formData||{}
      return url||''
    },
  },
  watch:{
    value:{
      immediate:true,
      handler(val){
        this.dialog = val
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formData.upload_file = val||{}
        this.formData.name = val.name||''
        this.formData.file_id = val.file_id||''
      }
    }
  },
  methods: {
    handleView(){
      this.isOpen = true
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, {})
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name,nameKey } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$set(this.formData, nameKey, data)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$emit('confirm',this.formData,this.form.index)
          this.dialog =false
          this.$emit('input',false)
        } else {
        }
      })

    },
    handleCancel(){
      this.formData.upload_no = ''
      this.formData.upload_file ={thumb_url:'',url:''}
      this.formData.upload_file_id=''
      this.$emit('input',false)
    },
    network() {
      return {
        updateDoc: async (params) => {
          params = {...params}
          let {
            upload_no,upload_file_id, documents_received
          } = this.formData||{}
          if(this.uploadType==2){
            params.covernote_no = upload_no
            params.covernote_document_id = upload_file_id
          }
          if(this.uploadType==1){
            params.policy_no = upload_no
            params.policy_document_id = upload_file_id
          }
          if(this.uploadType==3){
            params.documents_received = documents_received
          }
          let { data } = await updateDoc({...params,order_no:this.orderNo})
          this.formData.upload_no = ''
          this.formData.upload_file ={thumb_url:'',url:''}
          this.formData.upload_file_id=''
          this.$emit('refresh')
          this.$emit('input',false)
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #000000;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      margin-top: 30px;
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          color: #2D3748;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
  .black-color{
    color: #2D3748!important;
  }
}
/deep/.upload-card {
  width: 200px;
  height: 134.55px;
}
.view-dialog{
  /deep/.el-dialog__body{
    overflow-y: hidden;
    height: 100vh;

  }
  /deep/.el-dialog{
    &.is-fullscreen{
      overflow-y: hidden;
      z-index: 99999;
    }
    .el-dialog__close {
      color: $primary;
      font-size: 48px;
      border: 3px solid $primary;
      border-radius: 50%;
      margin-top: 20px;
      margin-right: 20px;
      z-index: 99999;
    }
  }
}
</style>
