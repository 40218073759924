<template>
  <div class="document">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <template v-if="isEndorsement">
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col >
            <div class="form-item">
              <span>Cover Note</span>
              <!--            {{isEndorsement?'Endorsement':''}}-->
              <el-form-item prop="covernote_document_file_id">
                <file-container-card
                  :edit="true"
                  card
                  :fileData="{
                    url: formData.covernote_document_file_url,
                    client_name: formData.covernote_document_file_client_name
                  }"
                  :otherData="{
                    idKey: 'covernote_document_id',
                    urlKey: 'covernote_document_file_url',
                    client_name:'covernote_document_file_client_name'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
                <!--              <v-upload v-model="formData.covernote_document_file_id"></v-upload>-->
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col>
            <div class="form-item ">
              <span>Cover Note Number</span>
              <el-form-item
                ref="covernote_no"
                prop="covernote_no">
                <v-input
                  v-model="formData.covernote_no"
                  is-upper
                  @blur="handlerCheckCovernote"/>
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col>
            <div class="form-item ">
              <span>Policy</span>
              <el-form-item prop="policy_document_file_id">
                <file-container-card
                  :edit="true"
                  :fileData="{
                    url: formData.policy_document_file_url,
                    client_name: formData.policy_document_client_name
                  }"
                  :otherData="{
                    idKey: 'policy_document_id',
                    urlKey: 'policy_document_file_url',
                    client_name:'policy_document_client_name'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />

              <!--              <v-upload v-model="formData.policy_document_file_id"></v-upload>-->
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col>
            <div class="form-item ">
              <span>Policy Number </span>
              <el-form-item prop="policy_no">
                <v-input
                  v-model="formData.policy_no"
                  @change="handlerPolicyNumber"/>
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
      </template>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <v-row-col v-if="!isCompany">
          <div class="form-item ">
            <span>HKID Card / Passport  </span>
            <el-form-item
              ref="hkid_file_id"
              :rules="rules.upload"
              prop="hkid_file_id">
              <file-container-card
                :edit="true"
                :fileData="formData.hkid_file"
                :otherData="{
                  idKey: 'hkid_file_id',
                  urlKey: 'hkid_file_url',
                  name:'hkid_file'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
            </el-form-item>
          </div>
        </v-row-col>
        <template v-else>
          <v-row-col >
            <div class="form-item ">
              <span>CI</span>
              <el-form-item
                ref="ci_file_id"
                prop="ci_file_id"
                :rules="rules.upload">
                <file-container-card
                  :edit="true"
                  :fileData="formData.ci_file"
                  :otherData="{
                    idKey: 'ci_file_id',
                    urlKey: 'ci_file_url',
                    name:'ci_file'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col >
            <div class="form-item">
              <span>BR</span>
              <el-form-item
                ref="br_file_id"
                prop="br_file_id"
              >
                <file-container-card
                  :edit="true"
                  :fileData="formData.br_file"
                  :otherData="{
                    idKey: 'br_file_id',
                    urlKey: 'br_file_url',
                    name:'br_file'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
          </v-row-col>
        </template>
        <v-row-col>
          <div class="form-item ">
            <span>Vehicle Registration Document </span>
            <el-form-item prop="vrd_file_id">
              <file-container-card
                :edit="true"
                :fileData="formData.vrd_file"
                :otherData="{
                  idKey: 'vrd_file_id',
                  urlKey: 'vrd_file_url',
                  name:'vrd_file'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col v-if="ncd!=0">
          <div class="form-item ">
            <span>NCB proof (e.g. Renewal Notice) </span>
            <el-form-item
              ref="ncb_file_id"
              prop="ncb_file_id">
              <file-container-card
                :edit="true"
                :fileData="formData.ncb_file"
                :otherData="{
                  idKey: 'ncb_file_id',
                  urlKey: 'ncb_file_url',
                  name:'ncb_file'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Proof of Address（optional）</span>
            <el-form-item
              ref="proof_of_address_file_id"
              prop="proof_of_address_file_id">
              <file-container-card
                :edit="true"
                :fileData="formData.proof_of_address_file"
                :otherData="{
                  idKey: 'proof_of_address_file_id',
                  urlKey: 'proof_of_address_file_url',
                  name:'proof_of_address_file'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
            </el-form-item>
          </div>
        </v-row-col>
        <!--        <v-row-col v-if="isCompany">-->
        <!--          <div class="form-item ">-->
        <!--            <span>Driver HKID Card / Passport </span>-->
        <!--            <el-form-item-->
        <!--              ref="hkid_file_id"-->
        <!--              :rules="rules.upload"-->
        <!--              prop="hkid_file_id">-->
        <!--              <file-container-card-->
        <!--                :edit="true"-->
        <!--                :fileData="formData.hkid_file"-->
        <!--                :otherData="{-->
        <!--                  idKey: 'hkid_file_id',-->
        <!--                  urlKey: 'hkid_file_url',-->
        <!--                  name:'hkid_file'-->
        <!--                }"-->
        <!--                @delete="handlerDeleteFile"-->
        <!--                @uploadSuccess="handlerUploadSuccess" />-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <!--        <v-row-col>-->
        <!--          <div class="form-item ">-->
        <!--            <span>Driving Licence </span>-->
        <!--            <el-form-item-->
        <!--              ref="driving_licence_file_id"-->
        <!--              :rules="rules.upload"-->
        <!--              prop="driving_licence_file_id">-->
        <!--              <file-container-card-->
        <!--                :edit="true"-->
        <!--                :fileData="formData.driving_licence_file"-->
        <!--                :otherData="{-->
        <!--                  idKey: 'driving_licence_file_id',-->
        <!--                  urlKey: 'driving_licence_file_url',-->
        <!--                  name:'driving_licence_file'-->
        <!--                }"-->
        <!--                @delete="handlerDeleteFile"-->
        <!--                @uploadSuccess="handlerUploadSuccess" />-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
      </el-row>
      <template   v-if="showMotor">
        <section
          v-for="(driver,d_index) in formData.driver_document"
          :key="'driver_'+d_index"
          class="dirver-line"
        >
          <div class="flex-col text-left driver-text-title">
            <span>Driver {{d_index+1}}</span>
          </div>
          <section class="flex-wrap">
            <div
              v-if="isOneOfDriver&&d_index!=0||isCompany||!isOneOfDriver"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>HKID</span>
              </div>
              <el-form-item
                :ref="`driver_document.${d_index}.id_file_id`"
                :prop="`driver_document.${d_index}.id_file_id`"
                :rules="rules.upload"
              >
                <file-container-card
                  :edit="true"
                  :fileData="driver.id_file"
                  :otherData="{
                    idKey: 'id_file_id',
                    urlKey: 'id_file_url',
                    name:'id_file',
                    index:d_index,
                    type:'driver'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
            <div class="form-item form-half" >
              <div class="flex-col text-left">
                <span>Driving Licence</span>
              </div>
              <el-form-item
                :ref="`driver_document.${d_index}.driving_licence_file_id`"
                :rules="rules.upload"
                :prop="`driver_document.${d_index}.driving_licence_file_id`"
              >
                <file-container-card
                  :edit="true"
                  :fileData="driver.driving_licence_file"
                  :otherData="{
                    idKey: 'driving_licence_file_id',
                    urlKey: 'driving_licence_file_url',
                    name:'driving_licence_file',
                    index:d_index,
                    type:'driver'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
            <div
              v-if="check70Years(driver.birth)"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>Medical Examination Certificate</span>
              </div>
              <el-form-item
                :ref="`driver_document.${d_index}.mec_file_id`"
                :prop="`driver_document.${d_index}.mec_file_id`"
              >
                <file-container-card
                  :edit="true"
                  :fileData="driver.mec_file"
                  :otherData="{
                    idKey: 'mec_file_id',
                    urlKey: 'mec_file_url',
                    name:'mec_file',
                    index:d_index,
                    type:'driver'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
            <template v-if="driver.has_accident==2">
              <div
                v-for="(item,a_index) in driver.accidents_file"
                :key="'accidents_file_'+a_index"
                class="form-item form-half">
                <div class="flex-col text-left">
                  <span>Police Statement {{a_index+1}} (optional）</span>
                </div>
                <el-form-item
                  :ref="`driver_document.${d_index}.accidents_file_id`"
                  :prop="`driver_document.${d_index}.accidents_file_id`"
                >

                  <file-container-card
                    :edit="true"
                    :fileData="item"
                    :otherData="{
                      idKey: 'accidents_file_id',
                      name:'accidents_file',
                      index:d_index,
                      a_index:a_index,
                      type:'driver'
                    }"
                    @delete="handlerDeleteFile"
                    @uploadSuccess="handlerUploadSuccess" />
                </el-form-item>
              </div>
            </template>
            <div
              v-if="driver.is_dangerous_driving==1"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>Court Summons (optional）</span>
              </div>
              <el-form-item
                :ref="`driver_document.${d_index}.driving_licence_file_id`"
                :prop="`driver_document.${d_index}.driving_licence_file_id`"
              >
                <file-container-card
                  :edit="true"
                  :fileData="driver.dangerous_driving_file"
                  :otherData="{
                    idKey: 'dangerous_driving_file_id',
                    urlKey: 'dangerous_driving_file_url',
                    name:'dangerous_driving_file',
                    index:d_index,
                    type:'driver'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />
              </el-form-item>
            </div>
          </section>
        <!--        <div-->
        <!--          v-if="driver.has_accident==2||driver.is_dangerous_driving==1"-->
        <!--          class="flex-col text-left upload-text">-->
        <!--          <span>-->
        <!--            *If there are more than one, please submit it to counter staff for follow-up.-->
        <!--          </span>-->
        <!--        </div>-->
        </section>
      </template>
      <div class="flex">
        <div class="flex-1">
          <template>
            <section>
              <div
                v-if="showMotor"
                class="hr-inline"></div>
              <div class="mg-b-20">
                <div class="form-item  document-file ">
                  <div class="flex mg-b-20">
                    <p class="fw-700 fs-16">Other Document</p>
                    <p
                      class="hand primary mg-l-10 fs-14 fw-400"
                      @click="handleAddOther">Add Document</p>
                  </div>
                  <div

                    class="theme-second-blue  mg-t-20">
                    <!--                    <file-container-card-->
                    <!--                      :edit="true"-->
                    <!--                      card-->
                    <!--                      :fileData="item"-->
                    <!--                      :otherData="{-->
                    <!--                        index: index,-->
                    <!--                      }"-->
                    <!--                      @delete="handlerDelete"-->
                    <!--                    />-->
                    <div
                      v-for="(item,index) in formData.other_documents"
                      :key="index"
                      class="flex-sb-center other-upload-box">
                      <p
                        class="other-file-name hand"
                        @click="openFile(item.url)">
                        <font-awesome-icon
                          v-if="item.name"
                          class="mg-r-8 primary"
                          icon="paperclip"></font-awesome-icon>
                        <span class="primary fs-14">{{item.name||'Please Upload'}}</span>
                      </p>
                      <span>
                        <span
                          class="hand"
                          @click="handleUploadOther(item,index)">{{ item.url?'Edit':'Upload' }}</span>
                        <span
                          class="hand"
                          @click="handleDelUploadOther(index)">Delete</span>
                      </span>
                    </div>
                  </div>


                  <!--                  <el-form-item prop="covernote_document_file_id">-->
                  <!--                    <v-upload-->
                  <!--                      ref="upload"-->
                  <!--                      v-model="formData.covernote_document_id"-->
                  <!--                      :multiple="true"-->
                  <!--                      :limit="10"-->
                  <!--                      @uploadSuccess="handlerDocumentSuccess"></v-upload>-->
                  <!--                  </el-form-item>-->

                </div>
              </div>
              <div
                class="flex-sb">
                <div class="form-item">
                  <span>Full Set Doc. Received?</span>
                  <div class="car-type-radio">
                    <el-form-item >
                      <v-radio-group
                        v-model="formData.documents_received"
                        :border="true"
                        :list="yesNoOptions"
                      />
                    </el-form-item>
                  </div>
                </div>
              </div>
            </section>
            <!--            company-->
          </template>
        </div>

      </div>
    </el-form>
    <upload-other-document
      v-model="showUpload"
      :form="other_upload"
      @confirm="handleUploadOtherConfirm"></upload-other-document>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState} from 'vuex'
import {checkCovernote, checkPolicyNumber} from '@api/policy'
import uploadOtherDocument from '@/views/components/dialog/uploadOtherDocument'

export default {
  name: 'document',
  components:{
    uploadOtherDocument
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    classType:{
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    },
    driverDocument:{
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return{
      oldFormDataLock:-1,
      showUpload:false,
      other_upload:{},
      formData:{
        hkid_file_id:'',
        hkid_file:{},
        hkid_file_url:'',

        proof_of_address_file_id:'',
        proof_of_address_file:{},
        proof_of_address_file_url:'',

        br_file_id:'',
        br_file:{},
        ci_file_id:'',
        ci_file:{},
        vrd_file_id:'',
        vrd_file:{},
        vrd_file_url:'',
        ncb_file_id:'',
        ncb_file:{},
        ncb_file_url:'',
        driving_licence_file_id:'',
        driving_licence_file:{},
        driving_licence_file_url:'',
        other_documents:[],
        documents_received:'',

        policy_no: '',
        covernote_no: '',
        policy_document_id: '',
        covernote_document_id: '',
        driver_document: []
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID','ncd','companyID','quoteInformationForm']),
    ...mapState('common', ['isBackPath']),
    isCompany(){
      return this.clientType==2
    },
    isOneOfDriver(){
      let {policy_holder_is_driver} = this.driverDocument||{}
      return policy_holder_is_driver==1
    },
    list(){
      return this.globalOptions.document_type
    },
    isEdit(){
      let {is_edit=''} = this.quoteInformationForm||{}
      return is_edit?true:false
    },
    documentList(){
      let result = []
      let arr =this.list
      arr.some(item=>{
        if (item.class_id == this.classType) {
          item.data.some(client=>{
            if(client.client_type===this.clientType)
            {
              result = client.items
              // return  this.clientType==1?true:false
            }
          })
        }
      })
      return result
      return list[this.productID]
    },
    showMotor(){
      return this.productID==10
    },
    isEndorsement()
    {
      return this.form.policy_type ==203||this.$route.query.isEndorsement=='true'
    },
    isQueryEndorsement(){
      return this.$route.query.isEndorsement=='true'
    },
    isRenew(){
      let {isCreateRenew=false} = this.quoteInformationForm||{}
      let {policy_type} = this.form||{}
      let {isDraft} = this.$route.query
      return policy_type==201&&isCreateRenew||policy_type==201&&isDraft!='true'
    },
    isRenewQuote(){
      let {isCreateRenew=false} = this.quoteInformationForm||{}
      let {policy_type} = this.form||{}
      let {isDraft} = this.$route.query
      return policy_type==201
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock)
        {
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key=>{
            if(val&&val[key]){
              this.formData[key] = val[key]
            }
          })
          let {ci_file={},proof_of_address_file={},policy_document={},covernote_document={},hkid_file={},br_file={},vrd_file={},ncb_file={},driving_licence_file={},other_documents,documents_received} = val||{}
          if(hkid_file)
            this.formData.hkid_file_id = hkid_file.file_id
          if(proof_of_address_file)
            this.formData.proof_of_address_file_id = proof_of_address_file.file_id
          if(br_file)
            this.formData.br_file_id = br_file.file_id
          if(ci_file)
            this.formData.ci_file_id = ci_file.file_id
          if(vrd_file)
            this.formData.vrd_file_id = vrd_file.file_id
          this.formData.ncb_file_id = ncb_file?ncb_file.file_id:''
          if(driving_licence_file)
            this.formData.driving_licence_file_id = driving_licence_file.file_id
          let {data} = other_documents||{}
          this.formData.other_documents = data||[]
          this.formData.documents_received = documents_received

          if(this.isQueryEndorsement&&!this.isBackPath){
            this.formData.policy_no = ''
            this.formData.covernote_no = ''
          }
          if(policy_document&&!this.isQueryEndorsement||policy_document&&this.isBackPath){
            let {url,file_id,client_name}=policy_document
            this.$set(this.formData,'policy_document_file_url',url)
            this.$set(this.formData,'policy_document_client_name',client_name)
            this.$set(this.formData,'policy_document_id',file_id)
          }
          if(covernote_document&&!this.isQueryEndorsement||covernote_document&&this.isBackPath){
            let {url,file_id,client_name}=covernote_document
            this.formData.covernote_document_file_url = url
            this.formData.covernote_document_file_client_name = client_name
            this.formData.covernote_document_id = file_id
          }
          if(this.isQueryRenew){
            // this.formData.required_documents = []
            // this.formData.documents = []
            this.formData.documents_received = ''
          }
        }
      }
    },
    isRenew: {
      deep: true,
      immediate: true,
      handler(val) {
        let {documents_received} = this.quoteInformationForm||{}
        if(val){
          // this.formData.required_documents = []
          // this.formData.documents = []
          this.formData.documents_received = ''
        }else
          this.formData.documents_received = documents_received
      }
    },
    driverDocument:{
      immediate:true,
      handler(val){
        let {driver} = val||{}
        this.formData.driver_document =driver||[]
      }
    }
  },
  methods:{
    handleUploadOtherConfirm(data,index){
      let {upload_file,file_id,name} = data||{}
      this.formData.other_documents[index] = {...upload_file,file_id,name}
    },
    handleUploadOther(item,index){
      this.other_upload = {...item,index}
      this.showUpload = true
    },
    handleDelUploadOther(item,index){
      this.formData.other_documents.splice(index,1)
    },
    handlerCheckCovernote(){
      if(this.isEndorsement)
        return
      if(this.formData.covernote_no)
        this.network().checkCovernote()
    },
    handlerPolicyNumber(){
      if(this.isEndorsement)
        return
      if(this.formData.policy_no)
        this.network().checkPolicyNumber()
    },
    submitForm(formName){
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          this.$emit('getFormData', formName, this.formData)
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,name,index,type,a_index } = otherData
      if(type=='driver'){
        if(idKey=='accidents_file_id'){
          this.formData.driver_document[index][idKey][a_index] = ''
          this.formData.driver_document[index][name][a_index] = {}
          let file_data = JSON.parse(JSON.stringify(this.formData.driver_document[index][name]))
          this.$set(this.formData.driver_document[index],name,file_data)
        }else {
          this.formData.driver_document[index][idKey] = ''
          this.formData.driver_document[index][name] = {}
          this.formData.driver_document[index][urlKey] = ''
        }

      }else {
        this.$set(this.formData, idKey, '')
        this.$set(this.formData, urlKey, '')
        this.$set(this.formData, nameKey, '')
        this.$set(this.formData, name, {})
      }
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,name,type,index,a_index } = otherData
      const { file_id,  url,path, } = data
      if(type=='driver'){
        if(idKey=='accidents_file_id'){
          this.formData.driver_document[index][idKey][a_index] = file_id
          this.formData.driver_document[index][name][a_index] = data
          let file_data = JSON.parse(JSON.stringify(this.formData.driver_document[index][name]))
          this.$set(this.formData.driver_document[index],name,null)
          this.$set(this.formData.driver_document[index],idKey,this.formData.driver_document[index][idKey])
          this.$set(this.formData.driver_document[index],name,file_data)

        }else{
          this.$set(this.formData.driver_document[index],name,null)
          this.formData.driver_document[index][idKey] = file_id
          this.formData.driver_document[index][name] = data
          this.formData.driver_document[index][urlKey] = urlKey
          this.$set(this.formData.driver_document[index],name,data)
        }

      }else{
        this.$set(this.formData, idKey, file_id)
        this.$set(this.formData, urlKey, url)
        this.$set(this.formData, name, data)
      }

      if(idKey=='hkid_file_id'&&this.isOneOfDriver)
      {
        this.$set(this.formData.driver_document[0],'id_file',data)
        this.$set(this.formData.driver_document[0],'id_file_id',file_id)
        this.$set(this.formData.driver_document[0],'id_file_url',url)

      }
      this.$refs['form'].clearValidate([idKey])

    },
    handlerDelete({otherData}){
      let {index} = otherData||{}
      this.formData.other_documents.splice(index,1)
      this.$refs.upload.clearFiles()
    },
    handlerDocumentSuccess({ data, result, otherData }){
      const { file_id,path,url } = data
      this.formData.other_documents.push({file_id,client_name:path,url:url})

    },
    handleAddOther(){
      this.formData.other_documents.push({file_id:'',client_name:'',url:''})
    },
    openFile(url){
      if(url){
        window.open(url,'_blank')
      }
    },
    network(){
      return{
        checkCovernote: async () => {
          let params = {
            covernote_no:this.formData.covernote_no,
            company_id:this.companyID,
          }
          let { data } = await checkCovernote(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Cover Note Number” has been stored in our system. Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
        checkPolicyNumber: async () => {
          let params = {
            policy_no:this.formData.policy_no,
            company_id:this.companyID,
          }
          let { data } = await checkPolicyNumber(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Policy Number” has been stored in our system. Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .document{
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    .document-file{
      .flex{
        margin: 5px 0;
        align-items: center;
        span{
          font-weight: normal!important;
        }
        i{
          margin-right: 10px;
        }
      }
    }
    .hr-inline{
      margin: 15px 0;
    }
  }
  .other-upload-box{
    border-radius: 4px;
    background:  #F7FAFC;
    padding: 16px;
    margin-top: 8px;
    flex: 1;
    .other-file-name{
      color:  #2D3748;
      font-size: 14px;
      font-style: normal;
      font-weight: 700!important;
      line-height: 150%;
    }
    span{
      span{
        color: $primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        display: inline-block;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .driver-text-title{
    color:#000;
    /* Medium/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .dirver-line{
    border-top: 1px solid #CBD5E0;
    padding-top: 20px;
  }
  .flex-wrap{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
</style>
