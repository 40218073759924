<!--狀態，保險-->
<template>
  <section class="policy-detail" >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div
        v-if="![203,204].includes(formData.policy_type)"
        class="form-item form-half mg-t-30">
        <el-form-item
          ref="ref_number"
          prop="ref_number"
        >
          <v-checkbox-group
            v-model="formData.tags"
            :list="claimVipList"
          ></v-checkbox-group>
        </el-form-item>
      </div>
      <v-title title="Policy Details"></v-title>
      <div>
        <div
          v-if="!isEndorsement"
          class="form-item">
          <span class="little-title">Policy Type</span>
          <div class="car-type-radio">
            <el-form-item prop="policy_type">
              <v-radio-group
                v-model="formData.policy_type"
                :border="true"
                :list="policyTypeSelectOption"
                @change="handleChangType"
              >
              </v-radio-group>
            </el-form-item>
          </div>
          <!--          <el-form-item>-->
          <!--            <div class="flex">-->
          <!--              <v-button-->
          <!--                disabled-->
          <!--                :class="formData.policy_type==200?'button-theme-disable':''"-->
          <!--              >New Policy</v-button>-->
          <!--              <v-button-->
          <!--                disabled-->
          <!--                :class="formData.policy_type==201?'button-theme-disable':''"-->
          <!--              >Renew Policy</v-button>-->
          <!--            </div>-->
          <!--          </el-form-item>-->
        </div>
        <div
          v-if="formData.policy_type==201"
          class="form-item form-half">
          <span >Ref. Number</span>
          <el-form-item
            ref="ref_number"
            prop="ref_number"
          >
            <v-input
              v-model="formData.ref_number"
            ></v-input>
          </el-form-item>
        </div>
        <div
          class="form-item ">
          <span  class="little-title">Client Type</span>
          <div class="car-type-radio">
            <el-form-item prop="client_type">
              <v-radio-group
                v-model="formData.client_type"
                :border="true"
                :disabled="productCompany||isEdit||isRenew"
                :list="userTypeOptions"
                @change="handlerPolicyData"
              >
              </v-radio-group>
            </el-form-item>
          </div>
        </div>
        <div
          v-if="formData.client_type==1"
          class="form-item">
          <span  class="little-title">Are you the vehicle owner?</span>
          <div class="car-type-radio">
            <el-form-item prop="client_type">
              <v-radio-group
                v-model="formData.is_policyholder"
                :border="true"
                :list="yesNo2Options"
                @change="handlerPolicyData"
              >
              </v-radio-group>
            </el-form-item>
          </div>
        </div>
        <template v-if="formData.is_policyholder==2&&formData.client_type==1">
          <el-row
            :gutter="10"
          >
            <v-row-col>
              <v-row-col same >
                <div class="form-item">
                  <span>Authorised Person Name</span>
                  <el-form-item
                    ref="agent_name"
                    prop="agent_name"
                    :rules="rules.input">
                    <v-input
                      v-model="formData.agent_name"
                    ></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
            </v-row-col>
            <v-row-col>
              <v-row-col same >
                <div class="form-item">
                  <span>Authorised Person Mobile Number</span>
                  <el-form-item
                    ref="agent_mobile_no"
                    prop="agent_mobile_no"
                  >
                    <v-input
                      v-model="formData.agent_mobile_no"
                      key-number
                      type="tel"
                      :maxlength="8"
                      prepend="+852"
                    ></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
            </v-row-col>
          </el-row>
        </template>
        <!--        motor-->
        <el-row
          v-if="isRenew"
          :gutter="10"
        >
          <v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Previous Insurer</span>
                <el-form-item >
                  <v-input
                    v-model="formData.previous_insurer"
                    is-upper
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span class="flex-sb-center">
                  <span>Previous Policy Number</span>
                  <span
                    v-if="isQueryRenew"
                    class="item-val color-theme">HKD {{amount_received|formatMoney(2)}}</span>
                </span>
                <el-form-item>
                  <v-input
                    v-model="formData.previous_policy_number"
                    is-upper
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </v-row-col>
        </el-row>

        <!--        motor-->
        <el-row
          v-if="!isEndorsement&&showOther"
          :gutter="10"
        >
          <v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Insurer</span>
                <el-form-item
                  prop="company_id">
                  <v-select
                    v-model.number="formData.company_id"
                    :list="companyAllList"
                    @change="handlerDate"></v-select>
                </el-form-item>
              </div>
              <div
                v-if="isOtherCompany"
                class="form-item">
                <el-form-item>
                  <v-input v-model="formData.insurer"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Insurer Type</span>
                <el-form-item prop="issue_date">
                  <v-input
                    v-model="formData.insurance_type"
                    :disabled="isEdit||isRenew"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Insurer Type</span>
                <el-form-item prop="issue_date">
                  <v-input
                    v-model="formData.insurance_type"
                    :disabled="isEdit||isRenew"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same>
              <div class="form-item">
                <span>Product Name</span>
                <el-form-item prop="issue_date">
                  <v-input
                    v-model="formData.product_name"
                    :disabled="isEdit||isRenew"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </v-row-col>
        </el-row>

        <el-row
          :gutter="20"
          justify="space-around">

          <el-col
            v-if="productID==12"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">

            <div class="form-item">
              <span  class="little-title">Plan Type</span>
              <div class="car-type-radio">
                <el-form-item
                  ref="client_type"
                  prop="client_type">
                  <v-radio-group
                    v-model="formData.plan_id"
                    :border="true"
                    :list="planTypeOptions"
                    :disabled="isEdit"
                    @change="handlerPlan"
                  >
                  </v-radio-group>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col
            v-if="productID==16"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">

            <div class="form-item">
              <span  class="little-title">Period of Insurance</span>
              <div class="car-type-radio">
                <el-form-item
                  ref="client_type"
                  prop="client_type">
                  <v-radio-group
                    v-model="formData.period_of_insurance"
                    :border="true"
                    :list="periodInsuranceOptions"
                    @change="handlerPeriod"
                  >
                  </v-radio-group>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col
            v-if="!isEndorsement"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item">
              <span>Effective Date</span>
              <el-form-item
                ref="effective_date"
                prop="effective_date">
                <v-date-picker
                  v-model="formData.effective_date"
                  @change="handlerDate"></v-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="productID==11||showTravel&&formData.plan_id==5"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Number of Day</span>
              <el-form-item prop="number_of_day">
                <v-input
                  v-model="formData.number_of_day"
                  type="number"
                  append="Days"
                  @change="handlerExpiryDate"></v-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Expiry Date</span>
              <el-form-item prop="expired_date">
                <v-date-picker
                  v-model="formData.expiry_date"
                  :isTime="formData.plan_id!=5&&productID==12||isDisable?false:true"
                  :disabled="(isUsed||isEndorsement)&&!isQueryRenew"
                  @change="handlerDate"></v-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="isShowMaintenance"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Maintenance Period</span>
              <el-form-item prop="maintenance_period">
                <v-date-picker
                  v-model="maintenance_period_date"
                  type="daterange"
                  @change="handlerDate"></v-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="productID==11"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Maintenance Period</span>
              <el-form-item prop="maintenance_period">
                <v-select
                  v-model="formData.maintenance_period_id"
                  :list="globalOptions.risk_period"
                ></v-select>
              </el-form-item>
            </div>
          </el-col>
          <div  v-if="showTravel">
            <!--            <el-col-->
            <!--              v-if="formData.plan_id==5"-->
            <!--              :xs="24"-->
            <!--              :sm="24"-->
            <!--              :md="12"-->
            <!--              :lg="12"-->
            <!--              :xl="12">-->
            <!--              <div class="form-item ">-->
            <!--                <span>Total Days</span>-->
            <!--                <el-form-item >-->
            <!--                  <v-input-->
            <!--                    :value="totalDay"-->
            <!--                    disabled></v-input>-->
            <!--                </el-form-item>-->
            <!--              </div>-->
            <!--            </el-col>-->
            <el-col
              v-if="formData.plan_id==5"
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12">
              <div class="form-item ">
                <span>Destination</span>
                <el-form-item >
                  <v-input
                    v-model="formData.destination"
                    is-upper></v-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12">
              <div class="form-item ">
                <span>No. of Person(s) to be insured</span>
                <el-form-item >
                  <v-input
                    v-model.number="formData.insured_person_numbers"
                    type="number"
                    :min="1"
                    @change="handlerPersonNO"></v-input>
                </el-form-item>
              </div>
            </el-col>
          </div>
        </el-row>

      </div>
    </el-form>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'
import {changePolicyType} from '@api/order'
import MixinFormat from '@/mixins/format'

export default {
  name: 'policyDetail',
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{
    form:{
      type: Object,
      default: ()=>({})
    },
    formDataLock: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      oldFormDataLock: -1,
      carSelect: 0,
      usageSelect:0,
      insurerIndex:0,
      maintenance_period_date:'',
      policy_no:'',
      policy_insurer:'',
      formData:{
        // carTypeID:0,
        carTypeName:'Private Car',
        tags:[],
        policy_type: 200,//r
        ref_number: '',//r
        is_policyholder:'',
        agent_mobile_no:'',
        agent_name:'',
        class_id: 1001,//r CLASS_PRIVATE_CAR = 1001|CLASS_COMMERCIAL_VEHICLE = 1002|CLASS_MOTORCYCLE = 1003|CLASS_MOTOR_TRADE = 1004
        client_type: 1,//r
        issue_date: '',
        effective_date: '',//r
        expiry_date:'',
        maintenance_period:'',
        maintenance_period_date_start:'',
        maintenance_period_date_end:'',
        number_of_day:'',
        maintenance_period_id:'',
        product_id:'',
        //travel
        plan_id:5,
        period_inusrance:1,
        insured_person_numbers_id:'',
        destination:'',
        period_of_insurance:1,
        insurance_type:'',
        product_name:'',
        insured_person_numbers:1,
        insurer:'',
        previous_insurer:'',
        previous_policy_number:'',
        company_id:''
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID','quoteInformationForm']),
    ...mapState('travel', ['travelerNo']),
    ...mapState('common', ['username']),
    amount_received(){
      let {premium} = this.quoteInformationForm||{}
      let {amount_received} = premium||{}
      return amount_received
    },
    isOtherCompany(){
      let {company_id} = this.formData
      return company_id==0
    },
    isEdit(){
      let {is_edit='',edit_all} = this.quoteInformationForm||{}
      if(edit_all)
        return false
      return false
    },
    isEditAll(){
      let {is_edit='',edit_all} = this.quoteInformationForm||{}
      return edit_all
    },
    class_id(){
      let {class_id} = this.$route.query||{}
      return class_id
    },
    isRenew(){
      return   this.formData.policy_type ==201||this.$route.query.isRenew == 'true'
    },
    companyList(){
      let  length = this.globalOptions.company.length
      return this.globalOptions.company
      return this.globalOptions.company.slice(5,length)
    },
    companyAllList(){
      let  arr = this.globalOptions.company
      let arr_last = [{
        name:'Other Insurer',
        id:0
      }]
      return arr.concat(arr_last)
    },
    showOtherInsurer(){
      let arr =  [1,2,3,4,6,7,8,9,10,11,12,13]
      return !arr.includes(this.formData.company_id)||this.insurerIndex==4
    },
    showContractors(){
      return this.productID ==11
    },
    isShowMaintenance(){
      let product = [17]
      return product.includes(this.productID)
    },
    showTravel(){
      return this.productID ==12
    },
    productCompany(){
      let product = [14,15,17,16,12]
      return product.includes(this.productID)
    },
    showOther(){
      return this.productID==18
    },
    totalDay(){
      let day= dayjs(this.formData.expiry_date).diff(dayjs(this.formData.effective_date),'day')
      day = day+1
      return day?Math.abs(day):0
    },
    isDisable(){
      let product = [10,13,15,16,11,12,14]
      return product.includes(this.productID)
    },
    isEndorsement()
    {
      return this.formData.policy_type ==203||this.$route.query.isEndorsement||false
    },
    isUsed(){
      let {order_status=0,edit_all} = this.quoteInformationForm||{}
      return order_status>10&&!edit_all
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    }
  },
  watch:{
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if(val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form:{
      immediate:true,
      handler(val){
        // if(this.formDataLock !== this.oldFormDataLock) {
        // 觸發更新就賦值，避免二次更新
        // this.oldFormDataLock = this.formDataLock
        Object.keys(this.formData).some(key => {
          if(key in val) {
            this.formData[key] = val[key]
            // // 用于匹配是否更新了信息
            // if(key in this.oldFormData) {
            //   this.oldFormData[key] = val[key]
            // }
          }
        })
        let {company_id,policy_no} = val||{}
        this.formData.company_id = company_id
        let date1 = dayjs(val.expiry_date).subtract(1,'day')
        let diffDay = date1.diff(val.effective_date,'day')
        if(this.isQueryRenew){
          this.formData.effective_date = dayjs(val.expiry_date).add(1,'day').format('YYYY-MM-DD 00:00')
          this.formData.expiry_date = dayjs(this.formData.effective_date).add(1,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
          this.formData.previous_policy_number = policy_no
          this.formData.previous_insurer = this.getOptionName('globalOptions.company',company_id)
          if(this.showOther){
            this.formData.previous_insurer = this.formData.previous_insurer||this.formData.insurer
          }
          // if(this.$route.query.isDraft=='false')
          // {
          let obj = {
            1:'E',
            2:'A',
            3:'L'
          }

          let sign = obj[this.username]
          let number =dayjs(val.expiry_date).format('YYYYMMDD')
          this.formData.ref_number = `${sign}${number}`
          // }
        }

        // let {issue_date=''} = val||{}
        // if(!issue_date)
        //   this.formData.issue_date = dayjs().format('YYYY-MM-DD')
        let {maintenance_period_date_start,maintenance_period_date_end} = val
        if(maintenance_period_date_start){
          this.maintenance_period_date = [maintenance_period_date_start,maintenance_period_date_end]
        }
        // let {isDraft} = this.$route.query||{}
        // let {tags} = this.formData||{}
        // let claimed = []
        // let vip = []
        // if(this.clientBlackData.claimed)
        //   claimed = [2]
        // if(this.clientBlackData.vip)
        //   vip = [1]
        // if(isDraft=='false'){
        //   this.formData.tags = [...claimed,...vip]
        // }else  this.formData.tags = [...tags]
      }
    },
    'formData.effective_date':{
      immediate:true,
      handler(val){
        let {period_of_insurance=1,number_of_day} = this.formData
        let time = dayjs(val).add(period_of_insurance,'year').subtract(1,'day').format('YYYY-MM-DD')
        if(this.formData.plan_id==5&&this.productID==12||this.productID==11)
        {
          if(number_of_day>0)
            this.formData.expiry_date = dayjs(val).add(number_of_day,'day').subtract(1,'day').format('YYYY-MM-DD')
        }
        else{
          this.formData.expiry_date =!this.isDisable? this.formData.expiry_date:time
        }
        if(this.formData.plan_id==6)
          this.formData.expiry_date = time

      }
    },
    productID:{
      immediate:true,
      handler(val){
        let product = [14,15,17]
        if(product.includes(val))
          this.formData.client_type=2
        if(val==16)
          this.formData.client_type=1
        if(val!=10){
          this.formData.class_id = Number(val+'01')
        }
      }
    },
    travelerNo:{
      immediate:true,
      handler(val) {
        this.formData.insured_person_numbers = val
      }
    },
    class_id:{
      immediate:true,
      handler(val){
        if(val){
          this.formData.class_id = Number(val)
        }
      }
    },
    '$route.query.isRenew':{
      immediate:true,
      handler(val){
        if(val)
        {
          this.formData.policy_type=201
          this.setQuoteInformationForm({...this.quoteInformationForm,policy_type: this.formData.policy_type})
          this.$emit('update:policyType',this.formData.policy_type)
        }
      }
    },
    clientBlackData:{
      immediate:true,
      handler(val) {
        let {isDraft} = this.$route.query||{}
        let {tags} = this.formData||{}
        let claimed = []
        let vip = []
        if(val.claimed)
          claimed = [2]
        if(val.vip)
          vip = [1]
        if(this.isQueryRenew){
          this.formData.tags = [...claimed,...vip]
        }else  this.formData.tags = [...tags]
      }
    },
    username:{
      immediate:true,
      handler(val){
        if(val){
          if(this.isQueryRenew){
            let obj = {
              1:'E',
              2:'A',
              3:'L'
            }
            let sign = obj[val]
            let number =dayjs(this.form.expiry_date).format('YYYYMMDD')
            this.formData.ref_number = `${sign}${number}`
          }
        }
      }
    }
  },
  created() {
    if(this.productID==18)
      this.formData.company_id = 0
    this.handlerPolicyData()
    let ncdList = this.getNcdList(this.formData.class_id)

    this.setNcdList(ncdList)
  },
  methods:{
    ...mapActions('quotation', ['setNcdList','setNcd','setCompanyID','setQuoteInformationForm']),
    ...mapActions('travel', ['setTravelNo']),
    handleChangType(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure to change policy type?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.network().changePolicyType()
      }).catch(()=>{
      })
    },
    handlerPersonNO(){
      this.setTravelNo(this.formData.insured_person_numbers)
    },
    submitForm(formName){
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          let params = {
            maintenance_period_date_start:this.maintenance_period_date?this.maintenance_period_date[0]:'',
            maintenance_period_date_end:this.maintenance_period_date?this.maintenance_period_date[1]:'',
          }
          this.formData.product_id = this.productID
          if(!this.showOther){
            delete this.formData.company_id
          }
          this.$emit('getFormData', formName,{...this.formData,...params})
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    //提交给父类
    hanlderChange(){
      let {class_id} = this.formData
      this.formData.carTypeName = this.getOptionData('carClassOptions',class_id).name
      let ncdList = this.getNcdList(class_id)
      this.setNcdList(ncdList)
      this.setNcd(0)
      this.handlerPolicyData()
    },
    handlerPlan(){
      if(this.formData.plan_id==6){
        let val = this.formData.effective_date
        let time = dayjs(val).add(1,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
        this.formData.expiry_date =time
      }
      this.$emit('policyData',this.formData)
    },
    handlerPeriod(){
      let val = this.formData.effective_date
      let {period_of_insurance=1} = this.formData
      let time = dayjs(val).add(period_of_insurance,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
      this.formData.expiry_date =time
      this.$emit('policyData',this.formData)
    },
    handlerPolicyData(){
      this.$emit('policyData',this.formData)
    },
    handlerDate(){
      this.$emit('update:effectDate',this.formData.effective_date)
      // this.handlerPolicyData()
    },
    handlerExpiryDate(){
      let {number_of_day,effective_date} = this.formData
      if(number_of_day>0&&effective_date&&number_of_day)
        this.formData.expiry_date = dayjs(effective_date).add(number_of_day,'day').subtract(1,'day').format('YYYY-MM-DD 23:59')
      else this.formData.expiry_date =''
    },
    network() {
      return {
        changePolicyType: async (params) => {
          let {policy_type} = this.formData||{}
          let {order_no} = this.$route.query||{}
          if(!order_no){
            this.setQuoteInformationForm({...this.quoteInformationForm,policy_type: policy_type})
            return
          }
          params = {
            order_no,
            policy_type
          }
          let {data} = await changePolicyType(params)
          this.$emit('refresh',true)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .policy-detail{
    .little-title{
      margin-bottom: 4px;
      margin-top: 20px;
      display: block;
      font-weight: 700;
      color: #2D3748;
    }
    .el-col{
      margin-right: 12px;
    }
    .policy-insurer{
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 64px;
      width: 100%;
      height: 80px;
        margin-bottom: 10px;
      border: 1px solid $primary;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff;
      img{
        width: 70%;
        display: block;
      }
    }
    .is-checked{
      background: $primary-bg;
      border: 2px solid $primary;
    }
    .car-type-radio{
      ::v-deep .v-radio-group{
        .el-radio-group{
          display: flex;
          flex-wrap: wrap;
          .el-radio__label{
            margin-left: 0;
            text-align: center;
          }
          .el-radio{
            margin-right: 12px;
            margin-bottom: 12px;
            color: $primary;
            border-color:$primary ;
            font-weight: bold;
            background: #fff;
          }
          .el-radio__input{
            display: none!important;
          }
          .is-bordered{
            margin-left: 0;
          }
          .is-checked{
            background: $primary-bg;
            color: $primary!important;
            border: 2px solid $primary;
            .el-radio__label{
              color: $primary!important;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
</style>
