<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2>Add Referral</h2>
      </div>
      <div
        class="dialog-body"
        align="center">
        <div class="filter-container">
          <div class="input-container">
            <v-input
              v-model="search"
              placeholder="Agency Code / Phone Number"
              :suffix="'el-icon-search'"
              @handlerChange="filterData" />
          </div>
        </div>
        <div
          v-if="list.length>0"
          class="transparent">
          <div>
            <label
              v-for="(item,index) in list "
              :key="index"
              class="payment-content"
              :class="item.id===formData.referral_id?'check-bload':''"
              @click="hanlderSelect(item)"
            >
              <div class="check-flex">
                <span
                  class="checked-box"
                  :class="item.id===formData.referral_id?'is-checked':''"></span>
              </div>
              <div class="flex-inline">
                <span class="margin-right-ten">
                  {{item.name}} &nbsp;
                </span>
                <span >
                  {{item.mobile_no}}
                </span>
              </div>

            </label>
          </div>
        </div>
        <div
          v-else
          class="none-data-transparent">
          <span>Please Search</span>
        </div>
      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            class="button-theme-block"
            @click="handlerRemove">Remove Referrer
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerAddRefer">Add Refer
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
export default {
  name: 'referDialog',
  mixins: [MixinOptions],
  data() {
    return {
      dialog:false,
      type: [],
      search: '',
      formData:{
        referral_id:'',
        name:'',
        mobile_no:'',
        commission_rate:'',
      },
      list:[]
    }
  },
  computed: {
    // list(){
    //   return this.globalOptions.referral
    // }
  },
  watch:{
    search:{
      immediate:true,
      handler(val){
        if(!val){
          let arr = JSON.parse(JSON.stringify(this.globalOptions.referral))
          this.list =arr
        }
      }
    }
  },
  created() {
    this.list = this.globalOptions.referral||[]
  },
  methods: {
    ...mapActions('quotation', ['setCommissionNet']),
    showReferDialog(){
      this.dialog = !this.dialog
    },
    handlerAddRefer(){
      if(!this.formData.referral_id) {
        this.$message({
          type: 'warning',
          message: '請先選擇用戶'
        })
        return
      }
      let {commission_rate} = this.formData||{}
      this.setCommissionNet(commission_rate)
      this.$emit('addReferr',this.formData)
      this.dialog =false
    },
    handlerRemove(){
      this.formData.referral_id =''
      this.formData.name =''
      this.formData.mobile_no =''
      this.setCommissionNet(null)
      this.$emit('addReferr',this.formData)
      this.dialog =false
    },
    hanlderSelect(data){
      let {id,name,mobile_no,commission_rate} = data
      this.formData.referral_id =id
      this.formData.name =name
      this.formData.mobile_no =mobile_no
      this.formData.commission_rate =commission_rate
    },
    filterData(){
      let data=  this.globalOptions.referral.filter(v => Object.values(v).some(v => new RegExp(this.search.toLocaleLowerCase() + '').test(v.toString().toLocaleLowerCase())))
      this.list = data
    },
    network() {
      return {
        getCustomerList: async () => {
          let { data } = await getCustomerList({
            type: this.type,
            search: this.search,
            page: this.pageConfig.currentPage
          })
          data.data.map((item, index) => {
            item.checked = false
            item.index = index
            return item
          })
          this.tableData = data.data
          this.pageConfig.total = data.total
          // this.pageConfig.pageSize = data.per_page
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-container {
  padding: 15px;
  color: #616266;
  width: 500px;
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px;
    padding: 0 15px 5px 15px;
    //   border-bottom: 1px solid #dddfe7;
    h1 {
      font-size: 24px;
    }
  }
  .payment-content{
    border:1px solid #91939A;
    //border-radius: 2px;
    margin-bottom: 15px;
    background: #fff;
    display: block;

    .flex{
      justify-content: space-between;
      align-items: center;
      padding:0 10px 10px;
      span{
        color: #000;
      }
    }
    .check-flex{
      display: flex;
      justify-content: flex-end;
    }
  }
  .dialog-body {
    height: 50vh;
    .filter-flex {
      margin-top: 10px;
      .form-item:first-child {
        margin-right: 15px;
        width: 400px;
      }
      .form-item:last-child {
        width: 400px;
      }
    }
    .transparent{
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 6px; // 横向滚动条
        height: 6px; // 纵向滚动条 必写
        display: block;
        background: #CBD5E0;
        cursor: pointer;

      }
      &::-webkit-scrollbar-thumb {
        background-color: #718096;
        border-radius: 3px;
        cursor: pointer;
        display: block;
      }
    }

    .filter-container {
      margin: 10px auto 20px 0;
      .input-container {
        ::v-deep .el-input{
          .el-input__inner{
            border-radius: 50px;
          }
        }
      }
    }
  }
  .radio-circle {
    display: inline-block;
    //   box-sizing: border-box;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 1px solid #D5D8DE;
    background: white;
    cursor: pointer;
  }
  .radio-circle-checked {
    position: relative;
    display: inline-block;
    //   box-sizing: border-box;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #409eff;
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: white;
    }
  }
    .check-flex{
      margin:-1px ;
    }
  .checked-box{
    display: inline-block;
    position: relative;
    border: 1px solid #91939A;
    //border-right: 0.5px!important;
    //border-top: 0!important;
    border-radius: 0;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #FFF;
    //z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);

    &::after
    {
      box-sizing: content-box;
      content: "";
      border: 1px solid #FFF;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 4px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      transition: transform .15s ease-in .05s;
      transform-origin: center;

    }
  }
  .is-checked{
    background: $primary;
    border-color: $primary;
    &::after{
      transform: rotate(45deg) scaleY(1);
    }
  }
  .check-bload{
    border: 2px solid $primary;
    //border-color: #076081;
  }
  .transparent{
    height: 40vh;
    overflow-y: auto;
  }
  .none-data-transparent{
    height: 40vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .flex-inline{
    display: flex;
    align-items: center;
    padding:0 10px 10px;
    span{
      color: #000;
    }
  }
}
</style>
